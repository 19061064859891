<template>
  <div class="activity-list">
    <van-sticky>
      <div class="tabs">
        <p v-for="item in tabs" :key="item.id" :class="{p_active:item.id === activeTab}" @click="changeTab(item.id)">
          {{item.name}}</p>
        <div class="tab_active" :style="`left:${leftNum}rem`"></div>
      </div>
    </van-sticky>
    <main>
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
        <div class="list_box">
          <div class="query">
            <input type="text" placeholder="请输入团队名称或活动标题查询" v-model="name">
            <button @click="select">搜索</button>
            <img src="~img/25.png">
          </div>
          <ul class="list">
            <li @click="toActivityInfo(item.id)" v-for="item in activityList" :key="item.id">
              <div class="left">
                <img :src="item.image">
                <div class="top_status">
                  <img v-if="item.is_enroll === 1" src="~img/47.png" alt="">
                  <img v-if="item.status === 2" src="~img/48.png" alt="">
                  <p v-if="item.is_enroll === 1">报名中</p>
                  <p v-if="item.status === 2">已结束</p>
                  <img v-if="item.status === 4" src="~img/59.png" alt="">
                </div>
                <div class="bom_status" v-if="item.type === 2">
                  <p>外部活动</p>
                </div>
              </div>
              <div class="right">
                <p class="p1 one-txt-cut">{{item.name}}</p>
                <p class="p2 one-txt-cut" v-if="item.team">{{item.team.name}}</p>
                <div>
                  <img src="~img/22.png">
                  <p class="one-txt-cut">活动地点:{{item.address}}</p>
                </div>
                <div v-if="item.label.indexOf('enroll_time') !== -1">
                  <img src="~img/23.png">
                  <p class="one-txt-cut">报名时间:{{item.enroll_start_time}}-{{item.enroll_end_time}}</p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </van-list>
    </main>
    <tabbar />
  </div>
</template>


<script>
import tabbar from "../../components/Tabbar.vue";

export default {
  components: {
    tabbar,
  },
  data() {
    return {
      tabs: [
        { id: 0, name: "全部活动" },
        { id: 1, name: "报名中" },
        { id: 2, name: "往期回顾" },
      ],
      activeTab: 0,
      leftNum: 0,
      limit: 10,
      page: 0,
      status: 0,
      history: 0,
      name: "",
      date: "",
      tema_id: "",
      activityList: [],
      total: 0,
      loading: false,
      finished: false,
    };
  },
  created() {
    this.name = this.getQueryValue("name");
  },
  mounted() { },
  methods: {
    select() {
      this.page = 1;
      this.activityList = [];
      this.finished = false;
      this.getActivity();
    },
    /*
        type: 活动类型 1联想精彩活动 2外部公益活动 0 全部
        limit: 每页显示多少个
        page： 当前页数
        status： 活动状态 1 报名中 2 完成 3 取消 0全部
        history: 往期回顾 1 ，0
        name: 团队名称或活动标题
        date: 日历日期时传入
        tema_id: 团队id
    */
    async getActivity() {
      const res = await this.axios.get(
        `activity?type=0&limit=${this.limit}&page=${this.page}&status=${this.status}&history=${this.history}&name=${this.name}&date=${this.date}&tema_id=${this.tema_id}`
      );
      if (res.code === 200) {
        console.log(res);
        const { list, meta } = res.data;
        this.total = meta.total;
        list.forEach((val) => {
          val.enroll_start_time = val.enroll_start_time.split(" ")[0];
          val.enroll_end_time = val.enroll_end_time.split(" ")[0];
          this.activityList.push(val);
        });
        this.loading = false;
        if (this.activityList.length >= this.total) {
          this.finished = true;
        }
      }
    },
    onLoad() {
      this.page++;
      this.getActivity();
    },
    changeTab(id) {
      this.activeTab = id;
      if (id === 0) {
        this.leftNum = 0;
        this.status = 0;
        this.history = 0;
      } else if (id === 1) {
        this.status = 1;
        this.leftNum = 2;
        this.history = 0;
      } else if (id === 2) {
        this.status = 2;
        this.history = 1;
        this.leftNum = 3.7;
      }
      this.finished = false;
      this.activityList = [];
      this.page = 1;
      this.getActivity();
    },
    toActivityInfo(id) {
      this.$router.push({ path: `/activityDetails?id=${id}` });
    },
  },
};
</script>


<style lang="less" scoped>
.activity-list {
  min-height: 80vh;
  font-family: SourceHanSansCN-Normal, SourceHanSansCN;
  .tabs {
    padding-top: 36px;
    padding-bottom: 20px;
    display: flex;
    position: relative;
    width: 92%;
    margin: auto;
    background: #f8f8f8;
    p {
      font-size: 27px;
      color: #000000;
      margin-right: 45px;
    }
    .p_active {
      font-size: 30px;
      font-weight: 500;
    }
    .tab_active {
      width: 58px;
      height: 6px;
      background: #bb262e;
      position: absolute;
      bottom: 5px;
      transition: 0.2s all;
    }
  }
  main {
    width: 92%;
    margin: auto;

    .list_box {
      margin-top: 30px;
      padding-top: 22px;
      padding-left: 30px;
      padding-right: 30px;
      background: #ffffff;
      border-radius: 18px;
      padding-bottom: 30px;
      overflow: hidden;
      .list {
        padding-top: 24px;
        li {
          height: 263px;
          border-bottom: 1px solid #e4e4e4;
          display: flex;
          align-items: center;
          .left {
            display: flex;
            align-items: center;
            position: relative;
            img {
              width: 196px;
              height: 196px;
              border-radius: 10px;
            }
            .top_status {
              position: absolute;
              width: 96px;
              height: 32px;
              top: 0;
              left: 0;
              img {
                width: 100%;
                height: 32px;
                border-radius: 0;
                position: absolute;
                top: 0;
              }
              p {
                font-size: 24px;
                color: #fff;
                text-align: center;
                line-height: 32px;
                position: relative;
              }
            }
            .bom_status {
              width: 117px;
              height: 32px;
              background: linear-gradient(226deg, #ffb9a2 0%, #f26a52 100%);
              border-radius: 16px 0px 6px 0px;
              font-size: 24px;
              color: #fff;
              text-align: center;
              line-height: 32px;
              position: absolute;
              right: 0;
              bottom: 0;
            }
          }
          .right {
            font-family: FZLTHJW--GB1-0, FZLTHJW--GB1;
            padding-left: 20px;
            .p1 {
              font-size: 28px;
              width: 400px;
            }
            .p2 {
              font-size: 24px;
              color: #6f6f6f;
              margin-bottom: 45px;
              margin-top: 5px;
              width: 400px;
            }
            div {
              display: flex;
              align-items: center;
              margin-top: 10px;
              img {
                width: 21px;
                margin-right: 16px;
              }
              p {
                font-size: 24px;
                color: #6f6f6f;
                // margin-top: 10px;
              }
            }
          }
        }
      }
      .query {
        display: flex;
        justify-content: space-between;
        position: relative;
        img {
          width: 31px;
          left: 22px;
          top: 50%;
          transform: translate(0, -50%);
          position: absolute;
        }
        button {
          font-size: 27px;
          color: #fff;
          width: 89px;
          height: 72px;
          border-radius: 10px;
          background: url("../../assets/img/24.png") no-repeat;
          background-size: contain;
        }
        input {
          width: 520px;
          height: 72px;
          font-size: 27px;
          background: #f1f1f1;
          border-radius: 10px;
          padding-left: 69px;
        }
        ::-webkit-input-placeholder {
          /* WebKit browsers，webkit内核浏览器 */
          color: #a4a4a4;
          font-family: SourceHanSansCN-Normal, SourceHanSansCN;
        }
        :-moz-placeholder {
          /* Mozilla Firefox 4 to 18 */
          color: #a4a4a4;
          font-family: SourceHanSansCN-Normal, SourceHanSansCN;
        }
        ::-moz-placeholder {
          /* Mozilla Firefox 19+ */
          color: #a4a4a4;
          font-family: SourceHanSansCN-Normal, SourceHanSansCN;
        }
        :-ms-input-placeholder {
          /* Internet Explorer 10+ */
          color: #a4a4a4;
          font-family: SourceHanSansCN-Normal, SourceHanSansCN;
        }
      }
    }
  }
}
</style>