<template>
  <div class="comment-details">
    <div class="go_back" @click="$router.go(-1)">
      <van-icon name="arrow-left" />
      <p>返回</p>
    </div>
    <div class="center">
      <div class="header-box">
        <img :src="commentUser.user.avatar" class="header-img">
        <!-- <img src="~img/36.png" class="v-img"> -->
      </div>
      <div class="comment-info">
        <p class="p1">{{commentUser.user.name}}</p>
        <p class="p2">{{commentUser.created_at}}</p>
        <p class="p3">{{commentUser.comment}}</p>
        <div class="img_box">
          <div class="imgs" v-for="(item,index) in commentUser.file" :key="index"
            @click="preview(commentUser.type,commentUser.file,item,index)">
            <img :src="item" v-if="commentUser.type === 1">
            <video :ref="`video${index}`" v-else class="video" :src="item"
              :poster="commentUser.file_image[index]"></video>
          </div>
        </div>
        <div class="info">
          <div class="ls" @click="commentLike">
            <img src="~img/32.png" class="img1" v-if="commentUser.has_like === 1">
            <img src="~img/31.png" class="img1" v-else>
            <span>{{commentUser.likes_count}}</span>
          </div>
          <div class="ls">
            <img src="~img/34.png" class="img3">
            <span>{{commentUser.comment_count}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="user-list">
      <div class="mas">
        <div @click="commentLike">
          <img src="~img/32.png" class="xin-img" v-if="commentUser.has_like === 1">
          <img src="~img/37.png" class="xin-img" v-else>
        </div>
        <div class="img-list">
          <img :src="item.user.avatar" v-for="(item,index) in likeList" :key="index">
        </div>
      </div>
      <div class="divider"></div>
    </div>
    <div class="comment-list">
      <div class="comment">
        <img src="~img/38.png" class="icon-img">
        <ul>
          <li v-for="item in levelTowList" :key="item.id">
            <img :src="item.user.avatar" class="header-img">
            <div class="text-box">
              <p class="p1">{{item.user.name}}</p>
              <p class="p2">{{item.comment}}</p>
              <p class="p3">{{item.created_at}}</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="input-box" :style="`bottom:${botNum}px`">
      <input type="text" v-model="value" placeholder="评论">
      <button @click="comment">发送</button>
    </div>
    <van-overlay :show="showVideo" @click="closeVideo">
      <van-icon name="close" color="#fff" @click="closeVideo" />
      <video class="video-box" ref="video" :src="videoUrl" autoplay controls></video>
    </van-overlay>
    <tabbar ref="tabbar" />
  </div>
</template>


<script>
import tabbar from "../../components/Tabbar.vue";
import { ImagePreview } from "vant";
import wxShare from "../../utils/wxShare.js";
export default {
  components: {
    tabbar,
    [ImagePreview.Component.name]: ImagePreview.Component,
  },
  data() {
    return {
      comment_id: "",
      id: "",
      commentUser: {
        user: {},
      },
      likeList: [],
      levelTowList: [],
      value: "",
      botNum: 0,
      isAndroid: false,
      showVideo: false,
      videoUrl: "",
    };
  },
  created() {
    this.comment_id = this.getQueryValue("comment_id");
    this.id = this.getQueryValue("id");
    this.getCommentInfo();
    this.getCommentList();
    this.getLike();
    let u = navigator.userAgent;
    this.isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; // android终端
    console.log(this.isAndroid);
  },
  mounted() {
    // this.botNum = this.$refs.tabbar.$el.offsetHeight;
    this.botNum = 40;
  },
  methods: {
    async getCommentInfo() {
      const res = await this.axios.get(`comment/${this.comment_id}`);
      if (res.code === 200) {
        this.commentUser = res.data;
        let doc = res.data.comment.substring(0, 20);
        console.log(doc);
        this.getActivity(doc);
        console.log(this.commentUser);
      }
    },
    async getCommentList() {
      const res = await this.axios.get(`comment/comments/${this.comment_id}`);
      console.log(res);
      if (res.code === 200) {
        this.levelTowList = res.data;
      }
    },
    async getLike() {
      const res = await this.axios.get(`comment/${this.comment_id}/like/list`);
      if (res.code === 200) {
        this.likeList = res.data.like_list;
        console.log(this.likeList);
      }
    },
    async commentLike() {
      const res = await this.axios.post(`comment/like/${this.commentUser.id}`);
      if (res.code === 200) {
        this.getLike();
        if (this.commentUser.has_like === 0) {
          this.commentUser.likes_count++;
          this.commentUser.has_like = 1;
        } else {
          this.commentUser.likes_count--;
          this.commentUser.has_like = 0;
        }
      }
    },
    async comment() {
      const res = await this.axios.post("comment", {
        activity_id: this.id,
        comment: this.value,
        comment_id: this.comment_id,
        type: 1,
      });
      if (res.code === 200) {
        this.value = "";
        this.commentUser.comment_count++;
        this.getCommentList();
        this.$toast("评论成功");
      } else {
        this.$toast(res.message);
      }
    },
    preview(type, list, item, index) {
      console.log(type);
      if (type === 1) {
        ImagePreview(list);
      } else {
        if (this.isAndroid) {
          this.showVideo = true;
          this.videoUrl = item;
        } else {
          let ref = eval("this.$refs.video" + index)[0];
          ref.play();
          console.log(ref);
        }
      }
    },
    closeVideo() {
      if (this.isAndroid) {
        this.$refs.video.pause();
        this.showVideo = false;
      }
    },
    async getActivity(doc) {
      const res = await this.axios.get(`activity/${this.id}`);
      if (res.code === 200) {
        let activity = res.data;
        console.log(activity);
        let comment = {
          id: this.comment_id,
          title: doc,
          image: activity.image,
          name: activity.name,
        };
        wxShare.commentShare(comment);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.comment-details {
  position: relative;
  .center {
    display: flex;
    padding: 43px 30px 36px 30px;
    background: #fff;
    .header-box {
      position: relative;
      width: 104px;
      height: 104px;
      .header-img {
        width: 104px;
        height: 104px;
        border-radius: 50%;
      }
      .v-img {
        width: 40px;
        height: 40px;
        position: absolute;
        right: 0;
        bottom: 0;
      }
    }

    .comment-info {
      padding-left: 30px;
      padding-top: 10px;
      flex: 1;
      .p1 {
        font-size: 28px;
      }
      .p2 {
        font-size: 22px;
        color: #6f6f6f;
      }
      .p3 {
        font-size: 28px;
        margin-top: 23px;
        margin-bottom: 20px;
      }
      .img_box {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-column-gap: 24px;
        grid-row-gap: 24px;
        .imgs {
          width: 170px;
          height: 170px;
          overflow: hidden;
          display: flex;
          justify-content: center;
          border-radius: 10px;
          img {
            height: 184px;
            width: auto;
          }
          .paly-box {
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
          }
        }
      }
      .info {
        display: flex;
        justify-content: flex-end;
        padding-right: 10px;
        margin-top: 40px;
        .ls {
          display: flex;
          align-items: center;
          img {
            margin-right: 5px;
          }
          .img1 {
            width: 26px;
            height: 21px;
          }
          .img2 {
            width: 31px;
            height: 22px;
          }
          .img3 {
            width: 26px;
            height: 24px;
            margin-left: 29px;
          }

          span {
            font-size: 22px;
            color: #acacac;
          }
        }
      }
    }
  }
  .user-list {
    padding: 37px 32px 0px 32px;

    .mas {
      display: flex;
      align-items: top;
      .xin-img {
        width: 32px;
        height: 28px;
        margin-top: 14px;
      }
      .img-list {
        display: flex;
        flex-wrap: wrap;
        padding-left: 26px;
        img {
          width: 55px;
          height: 55px;
          margin-right: 20px;
          margin-bottom: 20px;
          border-radius: 50%;
        }
      }
    }
    .divider {
      height: 2px;
      width: 100%;
      background: #ffffff;
      margin-top: 34px;
    }
  }
  .comment-list {
    padding: 43px 30px 0px 33px;
    .comment {
      display: flex;
      .icon-img {
        width: 30px;
        height: 28px;
        margin-top: 22px;
      }
      ul {
        padding-left: 27px;
        li {
          display: flex;
          padding-bottom: 43px;
          border-bottom: 2px solid #fff;
          margin-bottom: 40px;
          .header-img {
            width: 72px;
            height: 72px;
            border-radius: 50%;
          }
          .text-box {
            padding-top: 20px;
            padding-left: 15px;
            .p1 {
              font-size: 24px;
            }
            .p2 {
              font-size: 24px;
              color: #6f6f6f;
            }
            .p3 {
              font-size: 22px;
              color: #999999;
              margin-top: 15px;
            }
          }
        }
      }
    }
  }
  .input-box {
    width: 100%;
    height: 180px;
    position: fixed;
    border-top: 1px solid #c2c2c2;
    background: #f8f8f8;
    font-size: 0;
    input {
      height: 90px;
      background: #f8f8f8;
      font-size: 27px;
      padding-left: 30px;
      color: #a7a7a7;
      width: 100%;
    }
    button {
      width: 150px;
      height: 72px;
      background: url("../../assets/img/56.png") no-repeat;
      background-size: contain;
      position: absolute;
      right: 12px;
      top: 5%;
      // transform: translate(0, -50%);
      font-size: 27px;
      color: #fff;
    }
  }
  .video-box {
    width: 100%;
  }
  .van-icon-close {
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 999;
  }
  .go_back {
    height: 80px;
    font-size: 30px;
    display: flex;
    align-items: center;
    background: #fff;
    padding-left: 25px;
    p {
      margin-left: 10px;
    }
  }
}
</style>