<template>
  <div class="activity-details">
    <img :src="activity.banner" class="banner">
    <main>
      <h2>{{activity.name}}</h2>
      <p v-for="(item,index) in activity.labels" :key="index">{{item.label}}：{{item.value}}</p>
      <button v-if="activity.is_enroll === 1 && activity.has_enroll !== 1" @click="application">立即报名</button>
      <button v-if="activity.has_enroll === 1" @click="cancellation">已报名</button>
      <div class="detail-ht" v-html="activity.detail" @click="handleImageClick"></div>
      <ul>
        <li @click="likes">
          <img v-if="activity.has_like === 1" src="~img/27.png" class="img1">
          <img v-else src="~img/28.png" class="img1">
          <span>{{activity.like}}</span>
        </li>
        <li @click="show = true">
          <img src="~img/29.png" class="img2">
          <span>{{activity.comment_count}}</span>
        </li>
        <li @click="showfenx = true">
          <img src="~img/30.png" class="img3">
          <!-- <span>{{activity.share}}</span> -->
        </li>
      </ul>
    </main>

    <div class="comment">
      <div class="tabs">
        <p v-for="item in tabs" :key="item.id" :class="{p_active:item.id === activeTab}" @click="changeTab(item.id)">
          {{item.name}}</p>
        <div class="tab_active" :style="`left:${leftNum}rem`"></div>
      </div>
      <van-list v-model="loading" :finished="finished" :finished-text="finishedText" @load="onLoad">
        <ul v-if="activeTab == 1">
          <li v-for="item in commentList" :key="item.id">
            <div class="left">
              <img :src="item.user.avatar" alt="">
            </div>
            <div class="right">
              <div class="top">
                <p class="name">{{item.user.name}}</p>
                <p class="time">{{item.created_at}}</p>
              </div>
              <p class="dec">{{item.comment}}</p>
              <div class="img_box">
                <div class="imgs" v-for="(val,index) in item.file" :key="index" @click="preview(item.type,item.file)">
                  <img :src="val" v-if="item.type === 1">
                  <video v-else class="video" :src="val" :poster="item.file_image[index]" controls></video>
                  <div class="paly-box" v-if="item.type === 2 && isAndroid" @click="playvideo(val)"></div>
                </div>
              </div>
              <div class="info">
                <div class="ls" @click="commentLike(item)">
                  <img v-if="item.has_like === 1" src="~img/32.png" class="img1">
                  <img v-else src="~img/31.png" class="img1">
                  <span>{{item.likes_count}}</span>
                </div>
                <div class="ls">
                  <img src="~img/33.png" class="img2">
                  <span>{{item.read}}</span>
                </div>
                <div class="ls">
                  <router-link :to="{path:'/commentDetails',query:{comment_id:item.id,id:id}}">
                    <img src="~img/34.png" class="img3">
                    <span>{{item.comment_count}}</span>
                  </router-link>
                </div>
              </div>
            </div>
          </li>
          <li v-if="activeTab == 1 && commentList.length <= 0">
            <div class="nopl">暂无评论</div>
          </li>
        </ul>
      </van-list>
      <div v-if="activeTab == 2" v-html="activity.sum_up" class="zhong-ht"></div>
    </div>

    <van-popup v-model="show" round position="bottom" :style="{ height: '30%' }">
      <div class="select-activity">
        <van-button type="default" block size="large" @click="isImage = true;isVideo=false;show=false;showPopup=true;acceptType='image/*'">
          图片动态
        </van-button>
        <van-button type="default" block size="large" @click="isVideo = true;isImage=false;show=false;showPopup=true;acceptType='video/mp4,video/ogg,video/webm,video/mov'">
          视频动态
        </van-button>
        <div class="grey"></div>
        <van-button type="default" block size="large" @click="show = false">取消</van-button>
      </div>
    </van-popup>
    <van-popup v-model="showPopup" round>
      <div class="comment-box">
        <div class="comment-box-top">
          <van-icon name="arrow-left" size="18" />
          <p>发表评论</p>
        </div>
        <van-field v-model="message" rows="3" autosize type="textarea" placeholder="填写你想说的内容" show-word-limit />
        <div class="file-box">
          <div class="imgs" v-for="(item,index) in fileList" :key="index">
            <img :src="item" v-if="isImage">
            <video class="video" :src="item" v-if="isVideo"></video>
            <img src="~img/video.jpg" class="video-icon" v-if="isVideo">
            <div class="deletfile" @click="deleteFile(index)">删除</div>
          </div>
          <van-uploader :after-read="afterRead" :before-read="beforeRead" :accept='acceptType' multiple>
            <img src="~img/50.png" class="upfile">
          </van-uploader>
        </div>
        <button @click="setComment">发送</button>
      </div>
    </van-popup>
    <van-overlay :show="showLoading">
      <van-loading color="#0094ff">上传中...</van-loading>
    </van-overlay>
    <van-overlay :show="showVideo" @click="closeVideo">
      <van-icon name="close" color="#fff" @click="closeVideo" />
      <video class="video-box" ref="video" :src="videoUrl" autoplay controls></video>
    </van-overlay>
    <van-overlay :show="showfenx" @click="showfenx = false" lock-scroll>
      <img src="~img/58.png" class="fx-img">
      <p class="text">请点击右上角，<br /> 将活动分享给他人！</p>
    </van-overlay>
    <div class="isloading_box" v-if="showLoadingList">
      <van-loading color="#0094ff">加载中</van-loading>
    </div>
    <tabbar />
  </div>
</template>

<script>
import tabbar from "../../components/Tabbar.vue";
import { ImagePreview } from "vant";
import { Notify } from "vant";
import wxShare from "../../utils/wxShare.js";
export default {
  components: {
    tabbar,
    [ImagePreview.Component.name]: ImagePreview.Component,
    Notify,
  },
  data() {
    return {
      id: "",
      showLoading: false,
      tabs: [{ id: 1, name: "评论" }],
      activeTab: 1,
      leftNum: 0,
      activity: {},
      commentList: [],
      show: false,
      showPopup: false,
      message: "",
      fileList: [],
      limit: 5,
      page: 0,
      loading: false,
      finished: false,
      isImage: false,
      isVideo: false,
      fileIndex: 0,
      btnloading: true,
      isAndroid: false,
      showVideo: false,
      videoUrl: "",
      showfenx: false,
      showLoadingList: true,
      finishedText: "没有更多了",
      acceptType: "",
      user: {},
    };
  },
  created() {
    this.id = this.getQueryValue("id");
    this.user = JSON.parse(localStorage.getItem("user"));
    console.log(this.user);
    console.log(this.id);
    this.getActivity();
    let u = navigator.userAgent;
    this.isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; // android终端
  },
  mounted() { },
  methods: {
    changeTab(id) {
      this.activeTab = id;
      if (id === 1) {
        this.leftNum = 0;
        this.finishedText = "没有更多了";
      } else {
        this.leftNum = 1.3;
        this.finishedText = "";
      }
    },
    async getActivity() {
      const res = await this.axios.get(`activity/${this.id}`);
      if (res.code === 200) {
        this.activity = res.data;
        if (this.activity.sum_up) {
          this.tabs.push({ id: 2, name: "活动总结" });
        }
        console.log(this.activity);
        wxShare.getShareInfo(this.activity);
        document.title = this.activity.name;
      }
    },
    // 点赞
    async likes() {
      const res = await this.axios.post(`activity/like/${this.id}`);
      if (res.code === 200) {
        console.log(res);
        if (this.activity.has_like === 0) {
          this.activity.has_like = 1;
          this.activity.like++;
        } else {
          this.activity.has_like = 0;
          this.activity.like--;
        }
      }
    },
    async getActivityComment() {
      // 需要分页
      const res = await this.axios.get(
        `activity/comment/${this.id}?limit=${this.limit}&page=${this.page}`
      );
      if (res.code === 200) {
        console.log(res);
        const { list, meta } = res.data;
        this.loading = false;
        list.forEach((item) => {
          this.commentList.push(item);
        });
        this.showLoadingList = false;
        if (this.commentList.length >= meta.total) this.finished = true;
      }
    },
    onLoad() {
      this.page++;
      this.getActivityComment();
    },
    // 评论点赞
    async commentLike(com) {
      const res = await this.axios.post(`comment/like/${com.id}`);
      if (res.code === 200) {
        if (com.has_like === 0) {
          com.has_like = 1;
          com.likes_count++;
        } else {
          com.has_like = 0;
          com.likes_count--;
        }
      }
    },
    beforeRead(file) {
      console.log(file);
      if (Array.isArray(file)) {
        for (let i = 0; i < file.length; i++) {
          console.log(file[i]);
          if (
            !file[i].type.startsWith("image") &&
            !file[i].type.startsWith("video")
          ) {
            return false;
          }
          if (this.isImage) {
            if (file[i].type.startsWith("image")) {
              return true;
            } else {
              this.$toast("请上传图片文件");
              return false;
            }
          }
          if (this.isVideo) {
            if (file[i].type.startsWith("video")) {
              return true;
            } else {
              this.$toast("请上传视频文件");
              return false;
            }
          }
        }
      } else {
        if (!file.type.startsWith("image") && !file.type.startsWith("video"))
          return false;
        if (this.isImage) {
          if (file.type.startsWith("image")) {
            return true;
          } else {
            this.$toast("请上传图片文件");
            return false;
          }
        }
        if (this.isVideo) {
          if (file.type.startsWith("video")) {
            return true;
          } else {
            this.$toast("请上传视频文件");
            return false;
          }
        }
      }
    },
    afterRead(files) {
      console.log(files);
      if (Array.isArray(files)) {
        this.fileIndex = files.length;
        files.forEach((element) => {
          let formData = new FormData();
          formData.append("file", element.file);
          this.updata(formData);
        });
      } else {
        this.fileIndex = 1;
        let formData = new FormData();
        formData.append("file", files.file);
        this.updata(formData);
      }
    },

    async updata(file) {
      this.showLoading = true;
      const res = await this.axios.post("upload", file, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (res.code === 200) {
        console.log(res);
        this.fileList.push(res.data.url);
        if (this.fileList.length >= this.fileIndex) this.showLoading = false;
        console.log(this.fileList, "图片路径");
      } else {
        this.showLoading = false;
        this.$toast(res.message);
      }
    },
    deleteFile(index) {
      console.log(index);
      this.fileList.splice(index, 1);
    },
    async setComment() {
      if (!this.btnloading) return false;
      this.btnloading = false;
      if (this.message.length > 500) {
        this.$toast("内容不可超过500字");
        return false;
      }
      const res = await this.axios.post("comment", {
        activity_id: parseInt(this.id),
        comment: this.message,
        file: this.fileList,
        type: this.isImage ? 1 : 2,
      });
      console.log(res);
      if (res.code === 200) {
        console.log(res);
        Notify({ type: "success", message: "评论成功" });
        this.activity.comment_count++;
        this.showPopup = false;
        this.page = 1;
        this.finished = false;
        this.commentList = [];
        this.getActivityComment();
        this.btnloading = true;
        this.fileList = [];
        this.message = "";
      } else {
        this.$toast(res.message);
      }
    },
    preview(type, list) {
      console.log(type);
      if (type === 1) {
        ImagePreview(list);
      }
    },
    playvideo(val) {
      if (this.isAndroid) {
        this.showVideo = true;
        this.videoUrl = val;
        console.log(this.$refs.video);
        // this.$refs.video.play();
        // this.$refs.video
      }
    },
    closeVideo() {
      if (this.isAndroid) {
        this.$refs.video.pause();
        this.showVideo = false;
      }
    },
    application() {
      if (
        this.activity.recruit_range.indexOf(this.user.role.toString()) != -1
      ) {
        if (this.activity.max_person > this.activity.user_count) {
          let form = this.activity.form;
          localStorage.setItem("form", form);
          this.$router.push({ path: `/application?id=${this.activity.id}` });
        } else {
          this.$toast("报名人数已满");
        }
      } else {
        this.$toast("不在招募范围内");
      }
    },
    handleImageClick(event) {
      if (event.target.tagName === "IMG") {
        let list = [event.target.src];
        ImagePreview(list);
      }
    },
    cancellation() {
      this.$dialog
        .confirm({
          message: "是否取消报名",
        })
        .then(() => {
          // on close
          this.axios.delete(`user/activity/${this.id}`).then((res) => {
            if (res.code === 200) {
              this.$toast("取消成功");
              this.getActivity();
            } else {
              this.$toast(res.message);
            }
          });
        })
        .catch(() => {
          // on cancel
        });
    },
  },
  beforeDestory() {
    if (this.$refs.video) {
      this.$refs.video.pause();
    }
  },
};
</script>

<style lang="less" scoped>
.activity-details {
  padding-bottom: 200px;
  position: relative;
  // padding-top: 365px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  .nopl {
    font-size: 28px;
    margin-top: 10px;
  }
  .isloading_box {
    width: 100vw;
    height: 100vh;
    background: #fff;
    position: absolute;
    left: 0;
    top: 0;
    .van-loading {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  main {
    position: relative;
    border-radius: 18px;
    background: #fff;
    padding-top: 44px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 44px;
    h2 {
      font-size: 36px;
      font-weight: 500;
      margin-bottom: 20px;
    }
    .detail-ht {
      font-size: 28px;
      line-height: 44px;
      margin-top: 46px;
      width: 100%;
      overflow: hidden;
      /deep/p {
        margin-bottom: 18px;
      }
    }
    p {
      font-size: 28px;
      color: #646464;
      line-height: 50px;
    }
    button {
      width: 269px;
      height: 73px;
      background: url("../../assets/img/26.png") no-repeat;
      background-size: contain;
      font-size: 28px;
      color: #fff;
      // margin-bottom: 46px;
    }
    ul {
      display: flex;
      padding-left: 82px;
      padding-right: 82px;
      justify-content: space-between;
      margin-top: 30px;
      li {
        display: flex;
        align-items: center;
        img {
          width: 40px;
          margin-right: 10px;
        }
        span {
          font-size: 28px;
          color: #62646c;
        }
        .img1 {
          width: 38px;
        }
        .img2 {
          width: 44px;
        }
        .img3 {
          width: 36px;
        }
      }
    }
  }
  .comment {
    padding-top: 43px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 40px;
    background: #fff;
    margin-top: 30px;
    border-radius: 0 0 20px 20px;
    box-shadow: 0px 3px 5px 1px rgba(172, 168, 168, 0.75);
    .tabs {
      padding-top: 36px;
      padding-bottom: 20px;
      display: flex;
      position: relative;
      p {
        font-size: 27px;
        color: #000000;
        margin-right: 45px;
      }
      .p_active {
        font-size: 30px;
        font-weight: 500;
      }
      .tab_active {
        width: 58px;
        height: 6px;
        background: #bb262e;
        position: absolute;
        bottom: 5px;
        transition: 0.2s all;
      }
    }
    ul {
      li {
        margin-top: 45px;
        display: flex;
        .left {
          img {
            width: 60px;
            height: 60px;
            border-radius: 10px;
          }
        }
        .right {
          padding-left: 20px;
          padding-top: 10px;
          flex: 1;
          .top {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .name {
              font-size: 26px;
            }
            .time {
              font-size: 22px;
              color: #999999;
            }
          }
          .dec {
            font-size: 26px;
            color: #727272;
            line-height: 38px;
            margin-top: 10px;
            margin-bottom: 15px;
          }
          .img_box {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-column-gap: 24px;
            grid-row-gap: 24px;
            .imgs {
              width: 184px;
              height: 184px;
              overflow: hidden;
              display: flex;
              justify-content: center;
              border-radius: 10px;
              position: relative;
              img {
                height: 184px;
                width: auto;
              }
              /* 隐藏video 所有控件 */
              .video::-webkit-media-controls-enclosure {
                display: none;
              }
              .paly-box {
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
              }
            }
          }
          .info {
            display: flex;
            justify-content: space-between;
            padding-right: 10px;
            margin-top: 21px;
            border-bottom: 1px solid #d8d8d8;
            padding-bottom: 20px;

            .ls {
              display: flex;
              align-items: center;
              a {
                display: flex;
                align-items: center;
              }
              img {
                margin-right: 5px;
              }
              .img1 {
                width: 26px;
                height: 21px;
              }
              .img2 {
                width: 31px;
                height: 22px;
              }
              .img3 {
                width: 26px;
                height: 24px;
              }

              span {
                font-size: 22px;
                color: #acacac;
              }
            }
          }
        }
      }
      li:last-child {
        .info {
          border: none;
        }
      }
    }
    .zhong-ht {
      font-size: 24px;
    }
  }
  .comment-box {
    width: 650px;
    min-height: 642px;
    padding-bottom: 80px;
    button {
      width: 100%;
      height: 79px;
      background: url("../../assets/img/49.png") no-repeat;
      background-size: 100% 100%;
      position: absolute;
      bottom: 0;
      font-size: 28px;
      color: #fff;
    }
    .comment-box-top {
      height: 109px;
      display: flex;
      border-bottom: 1px solid #a4a4a4;
      align-items: center;
      padding-left: 34px;
      p {
        font-size: 30px;
        line-height: 30px;
      }
    }
    .file-box {
      padding-top: 80px;
      padding-left: 28px;
      padding-bottom: 20px;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-column-gap: 24px;
      grid-row-gap: 24px;
      .imgs {
        width: 184px;
        height: 184px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        border-radius: 10px;
        position: relative;
        img {
          height: 184px;
          width: auto;
        }
        .video-icon {
          width: 100px;
          height: auto;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
        /* 隐藏video 所有控件 */
        .video::-webkit-media-controls-enclosure {
          display: none;
        }
      }

      .upfile {
        width: 184px;
        height: 184px;
      }
      .deletfile {
        width: 100%;
        height: 40px;
        font-size: 20px;
        color: #fff;
        text-align: center;
        line-height: 40px;
        position: absolute;
        bottom: 0;
        background: rgba(0, 0, 0, 0.7);
      }
    }
  }
  .select-activity {
    padding-top: 40px;
    .van-button {
      border-top: none;
    }
    .grey {
      height: 20px;
      width: 100%;
      background: #f6f6f6;
    }
  }
  .van-overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    // position: relative;
    .fx-img {
      width: 78px;
      position: absolute;
      top: 10px;
      right: 35px;
    }
    .text {
      font-size: 15px;
      color: #fff;
      position: absolute;
      right: 85px;
      top: 50px;
    }
  }
  .video-box {
    width: 100%;
  }
  .van-icon-close {
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 999;
  }
}
</style>